




















import {Vue, Component, Prop, Watch} from 'vue-property-decorator'

@Component({
  name: 'BlkSidebar',
})
export default class BlkSidebar extends Vue {
  @Prop() groups!: Array<string>

  items: any = []

  hasPermission(permission: string) {
    return this.groups?.filter((x: any) => ['admins', permission].includes(x)).length > 0
  }

  translateGroup(group: string) {
    return this.$t('sidebar.groups.' + group)
  }

  @Watch('groups')
  get menuItems () {
    this.items = [
      {
        text: this.$t('sidebar.home'),
        to: '/',
        icon: 'home',
        show: true
      },
      {
        text: this.$t('sidebar.users'),
        to: this.localePath({ name: 'users' }),
        icon: 'users',
        show: this.hasPermission('user_approval')
      },
      {
        text: this.$t('sidebar.assets'),
        to: this.localePath({ name: 'assets' }),
        icon: 'coins',
        show: this.hasPermission('editing_offers')
      },
      {
        text: this.$t('sidebar.offers'),
        to: this.localePath({ name: 'offers' }),
        icon: 'store',
        show: this.hasPermission('editing_offers')
      },
      {
        text: this.$t('sidebar.highlightBanners'),
        to: this.localePath({ name: 'highlightBanners' }),
        icon: 'info-circle',
        show: this.hasPermission('marketing')
      },
      {
        text: this.$t('sidebar.transactions'),
        to: this.localePath({ name: 'transactions' }),
        icon: 'handshake',
        show: this.hasPermission('financial')
      },
      {
        text: this.$t('sidebar.account'),
        to: this.localePath({ name: 'accounts' }),
        icon: 'id-card',
        show: this.hasPermission('financial')
      },
      {
        text: this.$t('sidebar.waitlist'),
        to: this.localePath({ name: 'waitlist' }),
        icon: 'list',
        show: this.groups?.includes('admins')
      },
      {
        text: this.$t('sidebar.treasury'),
        to: this.localePath({ name: 'treasury' }),
        icon: 'dollar-sign'
      }
    ]

    return this.items
  }



}
