













































import { Vue, Component, Watch } from 'nuxt-property-decorator'

import { namespace } from 'vuex-class'
import gql from 'graphql-tag'
import BlkSidebar from '@/components/navigation/BlkSidebar.vue'
import BlkNavbar from '@/components/navigation/BlkNavbar.vue'
import BlkBreadcrumbs from '@/components/navigation/BlkBreadcrumbs.vue'

import { UserType } from '@/types'

const userStore = namespace('UserStore')

@Component({
  name: 'MainLayout',
  components: { BlkSidebar, BlkNavbar, BlkBreadcrumbs },
  meta: {
    breadcrumb: { text: 'Layout' }
  },
  apollo: {
    me: {
      prefetch: true,
      query: gql`
        {
          me {
            id
            groups
          }
        }
      `
    }
  }
})
export default class MainLayout extends Vue {
  me: UserType | null = null

  @userStore.Mutation('setUser') setUser: any

  @Watch('me')
  setStore(value: any) {
    this.setUser(value)
  }

  get isAdmin() {
    const groupsUser = this.me?.groups.filter((x) =>
      [
        'admins',
        'financial',
        'user_approval',
        'editing_offers',
        'marketing'
      ].includes(x)
    )
    const hasAdminGroups =
      groupsUser == undefined ? false : groupsUser.length > 0
    return !!(this.me && this.me.groups.length && hasAdminGroups)
  }

  logoutUser() {
    this.$auth.logout()
    // @ts-ignore
    window.location.href = `https://${this.$auth.strategy.options
      .domain as string}/v2/logout?returnTo=${location.origin}/login`
  }
}
