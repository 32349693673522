




import { Component, Vue, Watch } from 'nuxt-property-decorator'
import { BreadcrumbType } from '@/types'

@Component({
  name: 'BlkBreadcrumbs'
})
export default class BlkBreadcrumbs extends Vue {
  homeBreadcrumb = { text: 'home', to: 'index' }

  breadcrumbs: BreadcrumbType[] = []

  created() {
    this.breadcrumbs = this.getBreadcrumbs()
  }

  @Watch('$route')
  onRouteChange() {
    this.breadcrumbs = this.getBreadcrumbs()
  }

  getBreadcrumbs() {
    const breadcrumbs: BreadcrumbType[] = [
      this.translateBreadcrumb(this.homeBreadcrumb)
    ]
    const metas = this.$nuxt.context.route.meta

    metas.map((item: any) => {
      if (item.breadcrumb) {
        breadcrumbs.push(this.translateBreadcrumb(item.breadcrumb))
      }
    })

    return breadcrumbs
  }

  translateBreadcrumb(breadcrumb: BreadcrumbType) {
    return {
      text: breadcrumb.rawText || this.$tc(`breadcrumbs.${breadcrumb.text}`),
      to: this.localePath({ name: breadcrumb.to })
    }
  }
}
