import Vue from 'vue'

export default ({ app }) => {
  const numeral = require('numeral')
  require('numeral/locales')

  numeral.locale(app.i18n.locale)

  Vue.prototype.$numeral = numeral
}
