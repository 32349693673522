import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeLayers,
  FontAwesomeLayersText,
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

import {
  faArrowDown as freeFasFaArrowDown,
  faCheck as freeFasFaCheck,
  faCoins as freeFasFaCoins,
  faEdit as freeFasFaEdit,
  faEye as freeFasFaEye,
  faHandshake as freeFasFaHandshake,
  faHome as freeFasFaHome,
  faIdCard as freeFasFaIdCard,
  faList as freeFasFaList,
  faSignInAlt as freeFasFaSignInAlt,
  faSignOutAlt as freeFasFaSignOutAlt,
  faSpinner as freeFasFaSpinner,
  faStore as freeFasFaStore,
  faTimes as freeFasFaTimes,
  faTrash as freeFasFaTrash,
  faDollarSign as freeFasFaDollarSign,
  faUsers as freeFasFaUsers,
  faInfoCircle as freeFasFaInfoCircle
} from '@fortawesome/free-solid-svg-icons'

library.add(
  freeFasFaArrowDown,
  freeFasFaCheck,
  freeFasFaCoins,
  freeFasFaEdit,
  freeFasFaEye,
  freeFasFaHandshake,
  freeFasFaHome,
  freeFasFaIdCard,
  freeFasFaList,
  freeFasFaSignInAlt,
  freeFasFaSignOutAlt,
  freeFasFaSpinner,
  freeFasFaStore,
  freeFasFaTimes,
  freeFasFaTrash,
  freeFasFaDollarSign,
  freeFasFaUsers,
  freeFasFaInfoCircle
)

config.autoAddCss = false

Vue.component('fa', FontAwesomeIcon)
Vue.component('faLayers', FontAwesomeLayers)
Vue.component('faLayersText', FontAwesomeLayersText)
