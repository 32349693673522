


















import { Vue, Component } from 'vue-property-decorator'

@Component({
  name: 'BlkNavbar'
})
export default class BlkNavbar extends Vue {
  logout() {
    this.$auth.logout()
    //@ts-ignore
    window.location.href = `https://${this.$auth.strategy.options
      .domain as string}/v2/logout?returnTo=${location.origin}/login`
  }
}
