




import { Component, Vue } from 'nuxt-property-decorator'

@Component({
  name: 'Login',
  layout: 'login'
})
export default class Login extends Vue {
  beforeMount() {
    this.$auth.loginWith('auth0')
  }
}
