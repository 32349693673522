import { VuexModule, Module, Mutation } from 'vuex-module-decorators'
import { UserType } from '@/types'

@Module({
  name: 'user',
  stateFactory: true,
  namespaced: true
})
export default class UserStore extends VuexModule {
  user: UserType | null = null

  @Mutation
  setUser(user: UserType) {
    this.user = user
  }
}
