




import { Component, Vue } from 'nuxt-property-decorator'

@Component({
  name: 'Login'
})
export default class Login extends Vue {}
