import { VuexModule, Module, Mutation } from 'vuex-module-decorators'
import { BreadcrumbType } from '@/types'
import Vue from 'vue'

@Module({
  name: 'breadcrumbs',
  stateFactory: true,
  namespaced: true
})
export default class BreadcrumbStore extends VuexModule {
  breadcrumbs: BreadcrumbType[] = [{ text: 'Home', to: '/' }]

  @Mutation
  setBreadcrumbs(value: BreadcrumbType[]) {
    this.breadcrumbs = [this.breadcrumbs[0]].concat(value)
  }
}
