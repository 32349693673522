import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6cfc1d3c = () => interopDefault(import('../src/pages/accounts/index.vue' /* webpackChunkName: "pages/accounts/index" */))
const _3d853956 = () => interopDefault(import('../src/pages/assets.vue' /* webpackChunkName: "pages/assets" */))
const _621ae759 = () => interopDefault(import('../src/pages/assets/index.vue' /* webpackChunkName: "pages/assets/index" */))
const _7d1a7e32 = () => interopDefault(import('../src/pages/assets/new.vue' /* webpackChunkName: "pages/assets/new" */))
const _2e58cb98 = () => interopDefault(import('../src/pages/assets/details/_id.vue' /* webpackChunkName: "pages/assets/details/_id" */))
const _ef9bce04 = () => interopDefault(import('../src/pages/assets/edit/_id.vue' /* webpackChunkName: "pages/assets/edit/_id" */))
const _9e8f40f4 = () => interopDefault(import('../src/pages/highlightBanners.vue' /* webpackChunkName: "pages/highlightBanners" */))
const _7b04a989 = () => interopDefault(import('../src/pages/highlightBanners/index.vue' /* webpackChunkName: "pages/highlightBanners/index" */))
const _7d474f17 = () => interopDefault(import('../src/pages/highlightBanners/new.vue' /* webpackChunkName: "pages/highlightBanners/new" */))
const _0b2d9534 = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _4cf81450 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _6f92652c = () => interopDefault(import('../src/pages/offers.vue' /* webpackChunkName: "pages/offers" */))
const _bf6bc726 = () => interopDefault(import('../src/pages/offers/index.vue' /* webpackChunkName: "pages/offers/index" */))
const _9b57aa0a = () => interopDefault(import('../src/pages/offers/new.vue' /* webpackChunkName: "pages/offers/new" */))
const _5403b048 = () => interopDefault(import('../src/pages/offers/details/_id.vue' /* webpackChunkName: "pages/offers/details/_id" */))
const _0078492c = () => interopDefault(import('../src/pages/offers/edit/_id.vue' /* webpackChunkName: "pages/offers/edit/_id" */))
const _bbe0cd58 = () => interopDefault(import('../src/pages/signedin.vue' /* webpackChunkName: "pages/signedin" */))
const _12bf5de8 = () => interopDefault(import('../src/pages/transactions.vue' /* webpackChunkName: "pages/transactions" */))
const _6f77d66b = () => interopDefault(import('../src/pages/transactions/index.vue' /* webpackChunkName: "pages/transactions/index" */))
const _6d044c79 = () => interopDefault(import('../src/pages/transactions/new.vue' /* webpackChunkName: "pages/transactions/new" */))
const _2ab173c6 = () => interopDefault(import('../src/pages/transactions/details/_id.vue' /* webpackChunkName: "pages/transactions/details/_id" */))
const _915ff454 = () => interopDefault(import('../src/pages/treasury.vue' /* webpackChunkName: "pages/treasury" */))
const _762b6c85 = () => interopDefault(import('../src/pages/users.vue' /* webpackChunkName: "pages/users" */))
const _cb588970 = () => interopDefault(import('../src/pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _085dc496 = () => interopDefault(import('../src/pages/users/new.vue' /* webpackChunkName: "pages/users/new" */))
const _25510aaf = () => interopDefault(import('../src/pages/users/edit/_id.vue' /* webpackChunkName: "pages/users/edit/_id" */))
const _6cea7aa6 = () => interopDefault(import('../src/pages/waitlist.vue' /* webpackChunkName: "pages/waitlist" */))
const _39de138a = () => interopDefault(import('../src/pages/accounts/new.vue' /* webpackChunkName: "pages/accounts/new" */))
const _30c1924f = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/accounts",
    component: _6cfc1d3c,
    name: "accounts___pt-br"
  }, {
    path: "/assets",
    component: _3d853956,
    children: [{
      path: "",
      component: _621ae759,
      name: "assets___pt-br"
    }, {
      path: "new",
      component: _7d1a7e32,
      name: "assets-new___pt-br"
    }, {
      path: "details/:id?",
      component: _2e58cb98,
      name: "assets-details-id___pt-br"
    }, {
      path: "edit/:id?",
      component: _ef9bce04,
      name: "assets-edit-id___pt-br"
    }]
  }, {
    path: "/highlightBanners",
    component: _9e8f40f4,
    children: [{
      path: "",
      component: _7b04a989,
      name: "highlightBanners___pt-br"
    }, {
      path: "new",
      component: _7d474f17,
      name: "highlightBanners-new___pt-br"
    }]
  }, {
    path: "/login",
    component: _0b2d9534,
    name: "login___pt-br"
  }, {
    path: "/maintenance",
    component: _4cf81450,
    name: "maintenance___pt-br"
  }, {
    path: "/offers",
    component: _6f92652c,
    children: [{
      path: "",
      component: _bf6bc726,
      name: "offers___pt-br"
    }, {
      path: "new",
      component: _9b57aa0a,
      name: "offers-new___pt-br"
    }, {
      path: "details/:id?",
      component: _5403b048,
      name: "offers-details-id___pt-br"
    }, {
      path: "edit/:id?",
      component: _0078492c,
      name: "offers-edit-id___pt-br"
    }]
  }, {
    path: "/signedin",
    component: _bbe0cd58,
    name: "signedin___pt-br"
  }, {
    path: "/transactions",
    component: _12bf5de8,
    children: [{
      path: "",
      component: _6f77d66b,
      name: "transactions___pt-br"
    }, {
      path: "new",
      component: _6d044c79,
      name: "transactions-new___pt-br"
    }, {
      path: "details/:id?",
      component: _2ab173c6,
      name: "transactions-details-id___pt-br"
    }]
  }, {
    path: "/treasury",
    component: _915ff454,
    name: "treasury___pt-br"
  }, {
    path: "/users",
    component: _762b6c85,
    children: [{
      path: "",
      component: _cb588970,
      name: "users___pt-br"
    }, {
      path: "new",
      component: _085dc496,
      name: "users-new___pt-br"
    }, {
      path: "edit/:id?",
      component: _25510aaf,
      name: "users-edit-id___pt-br"
    }]
  }, {
    path: "/waitlist",
    component: _6cea7aa6,
    name: "waitlist___pt-br"
  }, {
    path: "/accounts/new",
    component: _39de138a,
    name: "accounts-new___pt-br"
  }, {
    path: "/en-us/",
    component: _30c1924f,
    name: "index___en-us"
  }, {
    path: "/en-us/accounts",
    component: _6cfc1d3c,
    name: "accounts___en-us"
  }, {
    path: "/en-us/assets",
    component: _3d853956,
    children: [{
      path: "",
      component: _621ae759,
      name: "assets___en-us"
    }, {
      path: "new",
      component: _7d1a7e32,
      name: "assets-new___en-us"
    }, {
      path: "details/:id?",
      component: _2e58cb98,
      name: "assets-details-id___en-us"
    }, {
      path: "edit/:id?",
      component: _ef9bce04,
      name: "assets-edit-id___en-us"
    }]
  }, {
    path: "/en-us/highlightBanners",
    component: _9e8f40f4,
    children: [{
      path: "",
      component: _7b04a989,
      name: "highlightBanners___en-us"
    }, {
      path: "new",
      component: _7d474f17,
      name: "highlightBanners-new___en-us"
    }]
  }, {
    path: "/en-us/login",
    component: _0b2d9534,
    name: "login___en-us"
  }, {
    path: "/en-us/maintenance",
    component: _4cf81450,
    name: "maintenance___en-us"
  }, {
    path: "/en-us/offers",
    component: _6f92652c,
    children: [{
      path: "",
      component: _bf6bc726,
      name: "offers___en-us"
    }, {
      path: "new",
      component: _9b57aa0a,
      name: "offers-new___en-us"
    }, {
      path: "details/:id?",
      component: _5403b048,
      name: "offers-details-id___en-us"
    }, {
      path: "edit/:id?",
      component: _0078492c,
      name: "offers-edit-id___en-us"
    }]
  }, {
    path: "/en-us/signedin",
    component: _bbe0cd58,
    name: "signedin___en-us"
  }, {
    path: "/en-us/transactions",
    component: _12bf5de8,
    children: [{
      path: "",
      component: _6f77d66b,
      name: "transactions___en-us"
    }, {
      path: "new",
      component: _6d044c79,
      name: "transactions-new___en-us"
    }, {
      path: "details/:id?",
      component: _2ab173c6,
      name: "transactions-details-id___en-us"
    }]
  }, {
    path: "/en-us/treasury",
    component: _915ff454,
    name: "treasury___en-us"
  }, {
    path: "/en-us/users",
    component: _762b6c85,
    children: [{
      path: "",
      component: _cb588970,
      name: "users___en-us"
    }, {
      path: "new",
      component: _085dc496,
      name: "users-new___en-us"
    }, {
      path: "edit/:id?",
      component: _25510aaf,
      name: "users-edit-id___en-us"
    }]
  }, {
    path: "/en-us/waitlist",
    component: _6cea7aa6,
    name: "waitlist___en-us"
  }, {
    path: "/en-us/accounts/new",
    component: _39de138a,
    name: "accounts-new___en-us"
  }, {
    path: "/",
    component: _30c1924f,
    name: "index___pt-br"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
